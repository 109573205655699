import {
	Button,
	ContentHeader,
	Form,
	FormCheckBox,
	FormField,
	LoadingMaskWrap,
	SubmitButton,
} from 'components'
import { Col, Container, ProgressBar, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { BASE_URL } from 'shared/config'
import { ITermsInfo } from '../types'
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import './SignatureComponent.css';


interface TermsAndAgreementProps {
	//onSubmit: (...args: any[]) => void
	setTermsInfo: (value: ITermsInfo) => void
	setSignature: (value: string) => void
	checkUpload: string | null
}

export const TermsAndAgreement = ({
	//onSubmit,
	setTermsInfo,
	setSignature,
	checkUpload,
}: TermsAndAgreementProps) => {
	const initialValues: ITermsInfo = {
		termsOfUse: false,
		privacyStatement: false,
		pharmacyPrivacy: false,
		signature: ''
	}

	const useFormInstance = useForm({
		defaultValues: initialValues,
	})

	const {
		getValues,
		register,
		watch,
		formState: { isSubmitting },
	} = useFormInstance

	const handleSubmit = async (values: any) => {
		const formValues = getValues();
		if (imageURL !== null) {
			formValues.signature = imageURL;
			
		}
		setTermsInfo(formValues);
		setSignature(imageURL)

	}
	

	const allTermsHasFalse = watch([
		'termsOfUse',
		'privacyStatement',
		'pharmacyPrivacy',
	]).includes(false)
	
	  //signature
	  const sigCanvas = useRef<SignatureCanvas>(null);
	  const [imageURL, setImageURL] = useState<string>('');
	  const [imageURLcheck, setimageURLcheck] = useState(true);
	
	  const clear = () => {
		if (sigCanvas.current) {
		  sigCanvas.current.clear();
		  setImageURL('');
		  setimageURLcheck(true)
		}
	  };
	
	  const save = () => {
		if (sigCanvas.current) {
			if (sigCanvas.current.isEmpty()) {
			  alert("Please provide signature first.");
			} else {
			  setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
			  setSignature(imageURL ?? '');
			  setimageURLcheck(false)
			}
		  }
	  };

	
	return (
		<>
			<Container fluid>
				<ContentHeader
					title="Terms & Agreement"
					backText="Back"
					backLink={-1}
				/>
				<Form useFormInstance={useFormInstance} onSubmit={handleSubmit}>
					<Row className="justify-content-center mb-5">
						<Col lg={10}>
							<FormField name="terms">
								<FormCheckBox
									name="termsOfUse"
									register={register}
									value={'termsOfUse'}
								>
									I agree and accept the{' '}
									<a
										className="link-secondary"
										href={`${BASE_URL}/static/pdf/MakoRx_CareConnect_CareCheckIn_Terms_of_Use_9.29.22.pdf`}
										target="_blank"
									>
										MakoRX Care Connect Care Check-In Terms
										Of Use
									</a>
									.
								</FormCheckBox>
								<FormCheckBox
									name="privacyStatement"
									register={register}
									value={'privacyStatement'}
									className="my-2"
								>
									I agree and accept the{' '}
									<a
										className="link-secondary"
										href={`${BASE_URL}/static/pdf/MakoRx_Privacy_Statement_Care Connect_Check in Tablet_9.29.22.pdf`}
										target="_blank"
									>
										MakoRX Privacy Statement Care Connect
										Care Check-In
									</a>
									.
								</FormCheckBox>
								<FormCheckBox
									name="pharmacyPrivacy"
									register={register}
									value={'pharmacyPrivacy'}
								>
									I agree and accept the{' '}
									<a
										className="link-secondary"
										href={'https://makorx-staging.s3.amazonaws.com/static/pdf/MakoRx_CareCheckIn_PharmacyAgreement_10.10.23.pdf'}
										target="_blank"
									>
										MakoRx Care Check-In Pharmacy Agreement
									</a>
									.
								</FormCheckBox>
							</FormField>
						</Col>
					</Row>
					<div className="footer w-75">
						<ProgressBar
							variant="secondary"
							now={100}
							className="col-lg-7 pull-left mt-3"
						/>
						<SubmitButton
							pending={isSubmitting}
							pendingText="Submitting"
							className="col-lg-auto pull-right"
							disabled={allTermsHasFalse || isSubmitting || imageURLcheck || checkUpload !== null}
						>
							Agree & Proceed
						</SubmitButton>
					</div>
					<input name="signature" hidden></input>
				</Form>
				<div className="signature-component">
					<p>This will be attached to the Pharmacy Agreement</p>
					<p>Place your signature here:</p>
					<div className="sigCanvasWrapper">
						<SignatureCanvas 
						penColor='black'
						canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
						ref={sigCanvas} />
					</div>
					<div className="buttons">
						<button onClick={clear}>Clear</button>
						<button onClick={save}>Save</button>
					</div>
					{imageURL ? 
						<img 
						src={imageURL}
						alt="Signature"
						className="signatureImage"
						/> 
						: null
					}
    			</div>
			</Container>
			{isSubmitting && <LoadingMaskWrap />}
		</>
	)
}
