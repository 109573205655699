import { useState,useEffect } from 'react'
import axios from 'axios'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { SideNav } from 'components'
import { BankingInfo } from './components/BankingInfo'
import { BusinessInfo } from './components/BusinessInfo'
import { BusinessQuestionnaire } from './components/BusinessQuestionnaire'
import { BusinessRepInfo } from './components/BusinessRepInfo'
import { RegistrationNav } from './components/NavBar/RegistrationNav'
import { TermsAndAgreement } from './components/TermsAndAgreement'
import { RegistrationResult } from './components/RegistrationResult'
import {
	IBankDetailsInfo,
	IBusinessInfo,
	IBusinessRepInfo,
	IQuestionnareInfo,
	ITermsInfo,
} from './types'
import { convertFieldsToSnakeCase, convertQs, dataURLToUint8Array } from 'common/Util'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { API_URL, STRIPE_PK, TOKEN } from 'shared/config'

import { PDFDocument, PDFTextField, singleQuote  } from 'pdf-lib';
import { saveAs } from 'file-saver';

export const Registration = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [businessRepInfo, setBusinessRepInfo] = useState<IBusinessRepInfo>()
	const [businessInfo, setBusinessInfo] = useState<IBusinessInfo>({
		name: '',
		type: 0,
		street: '',
		unitFloorBuilding: '',
		email: '',
		phoneNumber: '',
		city: '',
		state: '',
		zipCode: '',
		country: 'US',
		npi: '',
		ncpdp: '',
		isGpoMember : false,
		gpoMemberId: ''
	})
	const [bankingInfo, setBankingInfo] = useState<IBankDetailsInfo>({
		bankName: '',
		bankAccountType: '',
		accountName: '',
		accountNumber: '',
		abaRoutingNumber: '',
	})
	const [businessQs, setBusinessQs] = useState<IQuestionnareInfo>({
		plebotomy: null,
		licensed: null,
		phlebotomist: [{ phlebotomistName: '' }],
		trainExistingStaff: null,
		offerClia: null,
		isCliaWaivedSite: null,
		hasParkingLot: null,
		offerPrescription: null,
		cliaCertification: [],
		hasPublicBathroom: null,
		hasWiFi: null
		// cliaFile: null,
	})
	const [termsInfo, setTermsInfo] = useState<ITermsInfo>()
	const [signature, setSignature] = useState<string>('')
	const [checkSignature, setcheckSignature] = useState<string>('')
	const [stripeToken, setStripeToken] = useState<string>('')
	const [currentStep, setCurrentStep] = useState<Number>(0)
	const [isSuccess, setIsSuccess] = useState<boolean>(false)
	const [apiErrorMsg, setApiErrorMsg] = useState<string>('')
	const [currentDate, setCurrentDate] = useState(new Date());
	const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
	const [checkUploadedFile, setcheckUploadedFile] = useState<string | null>(null)
	const [checkUploadedCLIA, setcheckUploadedCLIA] = useState<string | null>(null)

	const headers = {
		'Content-Type': 'multipart/data',
		Authorization: `Token ${TOKEN}`,
	}

	const handleChangeStep = (value: any) => {
		if (value > currentStep) {
			setCurrentStep(value)
		}
	}

	const stripePromise = loadStripe(`${STRIPE_PK}`)

	//PDF
	async function fillForm() {
		const response = await fetch('/pdf/MakoRx_CareCheckIn_PharmacyAgreement_10.10.23.pdf');
		const formPdfBytes = await response.arrayBuffer();

		// Load a PDF with form fields
		const pdfDoc = await PDFDocument.load(formPdfBytes)

		//Convert image
		const imageUint8Array = dataURLToUint8Array(signature);
		const image = await pdfDoc.embedPng(imageUint8Array);

			// Get the form containing all the fields
			const form = pdfDoc.getForm()

			// Get all fields in the PDF by their names
			const nameField = form.getTextField('PHARMACY NAME')
			const addressField = form.getTextField('PHARMACY ADDRESS')
			const dateSignedField = form.getTextField('DATE SIGNED')
			const contactNameField = form.getTextField('CONTACT NAME')
			const phoneNumberField = form.getTextField('PHONE NUMBER')
			const emailField = form.getTextField('EMAIL')
			const signField = form.getButton('SIGNATURE');

			const pharmacy_name = businessInfo?.name
			const email = businessInfo?.email
			const phoneNumber = businessInfo?.phoneNumber
			const pharmacy_address = businessInfo?.street + ' ' + businessInfo?.city + ' ' + businessInfo?.state + ' ' + businessInfo?.zipCode
			const date_signed = currentDate.toLocaleString()
			const contact_name = businessRepInfo?.salutation + ' '  + businessRepInfo?.middleName + ' ' + businessRepInfo?.firstName + ' ' + businessRepInfo?.lastName

			phoneNumberField.setText(phoneNumber)
			emailField.setText(email)
			nameField.setText(pharmacy_name)
			addressField.setText(pharmacy_address)
			dateSignedField.setText(date_signed)
			contactNameField.setText(contact_name)
			signField.setImage(image)

			//FLATTEN FORM
			form.flatten();

			// Serialize the PDFDocument to bytes (a Uint8Array)
			const pdfBytes = await pdfDoc.save()
			const blob = new Blob([pdfBytes], { type: 'application/pdf' });

		const formDataFile = new FormData()
		blob ? formDataFile.append('file', blob, `makorx_privacy_agreement_10.10.23 - ${businessInfo?.name}.pdf`) : console.error('blob is null');
		console.log(`${API_URL}/upload/`)
		axios
			.post(`${API_URL}/upload/`, formDataFile, {
				headers,
			})
			.then((response) => {
				if (response.status === 201) {
					setcheckUploadedFile(response.data.file_url)
				} 
			})
			.catch((err) => {
				console.log(err, 'error')
				setApiErrorMsg(err.response.data[0])
			})

			//saveAs(blob, 'filled_form.pdf');

	}

	async function uploadCLIA() {

		const formDataCLIA = new FormData()
		formDataCLIA.append('file', businessQs.cliaCertification[0]) 
			axios
			.post(`${API_URL}/upload/`, formDataCLIA, {
				headers,
			})
			.then((response) => {
				if (response.status === 201) {
					setcheckUploadedCLIA(response.data.file_url.split(' ').join('%20'))
				} 
			})
			.catch((err) => {
				console.log(err, 'error')
			})
	}

	useEffect(() => {
		if (checkUploadedFile) {
			handleSubmit();
		}
	}, [checkUploadedFile]);
	

	useEffect(() => {
		//setcheckSignature(signature);
		//fillForm()
		//uploadCLIA()
		const processUpload = async () => {
			if (businessQs.cliaCertification[0] && signature) {
				await uploadCLIA();
			}
			if (signature) {
				await fillForm();
			}
		};
	
		processUpload();
	}, [termsInfo,signature]);




	const handleSubmit = () => {

		if (businessInfo && businessRepInfo && businessQs && bankingInfo) {
			const credentials = {
				email: businessRepInfo?.email,
				password: businessRepInfo?.password,
			}

			const personalInfo = {
				lastName: businessRepInfo?.lastName,
				firstName: businessRepInfo?.firstName,
				phoneNumber: businessRepInfo?.phoneNumber,
				salutation: businessRepInfo?.salutation,
				middleName: businessRepInfo?.middleName,
			}

			const convertedQuestionnaire = convertQs(businessQs)

			const params = {
				partner: convertFieldsToSnakeCase(businessInfo),
				business_representative: convertFieldsToSnakeCase(personalInfo),
				auth_credentials: convertFieldsToSnakeCase(credentials),
				bank_details: {
					credit_card_token: stripeToken || null,
					...convertFieldsToSnakeCase(bankingInfo),
				},
				questionnaires: convertedQuestionnaire,
				// terms: camelToUnderscore(termsInfo),
			}
		

			params.partner.clia_certification = checkUploadedCLIA

			const formData = new FormData()

			formData.append('data', JSON.stringify(params))

    		const fileDataParams = {
				name: "Signed Privacy Agreement",
				url: checkUploadedFile,
				mimetype: "application/pdf"
			}

			formData.append('file', JSON.stringify(fileDataParams))
			
			axios
				.post(`${API_URL}/registration/`, formData, {
					headers,
				})
				.then((response) => {
					if (response.status === 201) {
						setIsSuccess(true)
						navigate('/success')
					} else {
						setIsSuccess(false)
						navigate('/error')
					}
				})
				.catch((err) => {
					console.log(err, 'error')
					setApiErrorMsg(err.response.data[0])
					navigate('/error')
				})
		}
	}

	return (
		<>
			<Elements stripe={stripePromise}>
				<SideNav
					className={
						!location.pathname.includes('success') &&
						!location.pathname.includes('error')
							? 'bg-primary fixed-left'
							: 'fixed-top'
					}
				>
					{!location.pathname.includes('success') &&
						!location.pathname.includes('error') && (
							<RegistrationNav currentStep={currentStep} />
						)}
				</SideNav>
				<Routes>
					<Route
						path="/"
						element={
							<BusinessInfo
								businessInfo={businessInfo}
								setBusinessInfo={setBusinessInfo}
								setCurrentStep={handleChangeStep}
							/>
						}
					/>
					<Route
						path="/business-rep-info"
						element={
							<BusinessRepInfo
								businessRepInfo={businessRepInfo}
								setBusinessRepInfo={setBusinessRepInfo}
								setCurrentStep={handleChangeStep}
							/>
						}
					/>
					<Route
						path={'/banking-info'}
						element={
							<BankingInfo
								bankingInfo={bankingInfo}
								setBankingInfo={setBankingInfo}
								setCurrentStep={handleChangeStep}
								setStripeToken={setStripeToken}
								stripeToken={stripeToken}
							/>
						}
					/>
					<Route
						path={'/business-questionnaire'}
						element={
							<BusinessQuestionnaire
								businessQs={businessQs}
								setBusinessQs={setBusinessQs}
								setCurrentStep={setCurrentStep}
							/>
						}
					/>
					<Route
						path={'/terms'}
						element={
							<TermsAndAgreement
								setTermsInfo={setTermsInfo}
								setSignature={setSignature}
								checkUpload={checkUploadedFile}
								//onSubmit={handleSubmit}
							/>
						}
					/>
					<Route
						path={'/success'}
						element={
							<RegistrationResult
								email={businessRepInfo?.email}
								success={isSuccess}
								contract={checkUploadedFile}
							/>
						}
					/>
					<Route
						path={'/error'}
						element={
							<RegistrationResult
								email={businessInfo?.email}
								success={isSuccess}
								errorMsg={apiErrorMsg
									?.split(':')[1]}
								contract={checkUploadedFile}
							/>
						}
					/>
				</Routes>
			</Elements>
		</>
	)
}
