import { faCirclePlus, faCircleXmark, faInfoCircle} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { useState, useRef } from 'react'
import { Tooltip } from 'react-tooltip';

type InputProps = {
	name: string
	register: UseFormRegister<any>
	required?: boolean
	className?: string
	placeholder?: string
	hasAppendButton?: boolean
	onClickAppend?: React.MouseEventHandler<HTMLButtonElement>
	fieldCount?: any
	onClickRemove?: React.MouseEventHandler<HTMLButtonElement>
	type?: 'text' | 'number' | 'date' | 'time' | 'password' | 'hidden'
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
	value?: any
	hasError?: boolean
    errorMessage?: string | null,
	bookingDiscount?: boolean | string;
}

export const FormTextInput = ({
	name,
	register,
	placeholder,
	hasAppendButton,
	onClickAppend,
	fieldCount,
	onClickRemove,
	type = 'text',
	value,
	onChange,
	hasError,
 	errorMessage, 
	bookingDiscount = false,
}: 
InputProps) => {

	 
	 const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
	 const handleDelayedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	   if (timeoutId) {
		 clearTimeout(timeoutId);
	   }
	   
	   if (onChange) {
		 const newTimeoutId = setTimeout(() => {
		   onChange(e);
		 }, 400); 
   
		 setTimeoutId(newTimeoutId);
	   }
	 };
	 const icon = bookingDiscount ? (
		<span>
			<FontAwesomeIcon
				icon={faInfoCircle}
				className="rounded-right my-2 py-2 px-1 mx-1"
				size="1x"
				data-tooltip-id="my-tooltip"
				data-tooltip-html="MakoRx and health plan members,
				 enter the member ID found on your health insurance card as your discount code.  <br />  
				 Only active enrolled members are eligible for discounts and restrictions may apply."
			/>
			<Tooltip id="my-tooltip" />
		</span>
	) : null;
	 
	return (
		<>
			<div className="input-group rounded-left">
				{icon}
				<input
					type={type}
					className="form-control rounded-right mt-2"
					{...register(name)}
					placeholder={placeholder}
					onChange={handleDelayedChange}
					{...(value && {
						value: value,
					})}
				/>
				{fieldCount > 1 && (
					<button
						className="icon-only"
						onClick={onClickRemove}
						type="button"
					>
						<FontAwesomeIcon
							icon={faCircleXmark}
							className="text-secondary"
							size="1x"
						/>
					</button>
				)}
			</div>
			{hasError && errorMessage && (
				<small className="text-danger">{errorMessage}</small>
			)}
			{hasAppendButton && (
				<button
					className="icon-only"
					onClick={onClickAppend}
					type="button"
				>
					<FontAwesomeIcon
						icon={faCirclePlus}
						className="text-secondary"
						size="2x"
					/>
				</button>
			)}
		</>
	)
}
